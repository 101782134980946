import React from 'react'

import Layout from '../components/layout'
import Container from '../components/container'
import SEO from '../components/seo'

const NotFoundPage = () => (
  <Layout>
    <SEO title='404: Not found' />
    <Container>
      <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', paddingTop: 100}}>
        <h1>NOT FOUND 🙅</h1>
        <p>
          You just hit a route that doesn&#39;t exist <br />
          Oh, the sadness... 😞
        </p>
      </div>
    </Container>
  </Layout>
)

export default NotFoundPage
